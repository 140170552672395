import classNames from 'classnames';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import ReactTable, { TableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../components/common/Icon/Icon';
import { dataType, deleteResource, searchResource, setRowsPerPage } from '../../features/dashboard/dashboardSlice';
import { AppDispatch, RootState } from '../../store';
import css from './ListView.module.scss'
import { useCallback, useEffect, useState } from 'react';
import FullScreenPopup from './FullScreenPopup';
import Image from '../../components/common/Image/Image';
import CategoryOverlay from '../../components/common/CategoryOverlay/CategoryOverlay';
import { getTimeString } from '../../utils';
import { downloadResource } from '../../features/common/commonSlice';
import DownloadPopup from '../../components/Dashboard/DownloadPopup';
import DeletePopup from '../../components/Dashboard/DeletePopup';
import NoData from './NoData';
import { useToast } from '../../utils/providers/toastProvider';

const Pagination = (props: any) => {
    const { rowsPerPage, data } = useSelector((state: RootState) => state.dashboard);
    const {rowCount, onChangeRowsPerPage, onChangePage, paginationRowsPerPageOptions} = props;
    const currentPage = data?.currentPage;
    const totalPage = data?.totalPage;

    const handlePrev = () => {
        if (currentPage === 1) return;
        onChangePage(currentPage -1);
    };

    const handleNext = () => {
        if (currentPage === rowCount) return;
        onChangePage(currentPage + 1);
    };

    const options = paginationRowsPerPageOptions.map((item: number) => ({id: item.toString(), label: item.toString(), value: item}));
    return (
        <div className={css.pagination}>
            <span>{'Rows per page'}</span>
            <Dropdown
                className={css.perPage}
                direction="up"
                options={options}
                selected={rowsPerPage.toString()}
                onSelect={(ev: any) => {
                    //setPerPage(ev);
                    onChangeRowsPerPage(ev.value);
                }}
            />
            <div className={css.controls}>
                <div className={classNames(css.prev, {[css.disabled]: currentPage === 1})} onClick={handlePrev} />
                <div className={css.currentPage}><span>{`Page ${currentPage}`}</span>{` of ${totalPage}`}</div>
                <div className={classNames(css.next, {[css.disabled]: currentPage === totalPage})} onClick={handleNext} />
            </div>
        </div>
    );
};

const SortIcon = (props: any, order: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={'1.25rem'} height={'1.25rem'} viewBox="0 0 20 20" fill="none">
            <path d="M15 11.9038L5 11.9038L10 16.4998L15 11.9038Z" fill="#BDB6FF"/>
            <path d="M15 8.09596L10 3.5L5 8.09596L15 8.09596Z" fill="#5D6073"/>
        </svg>
    );
};

const ListView = ({type}: {type?: 'all' | 'image' | 'video'}) => {
    const { showToast } = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const { data, contentType, rowsPerPage, createdDateFrom, createdDateTo, broadDate, keyword } = useSelector((state: RootState) => state.dashboard);
    const [selectedItem, setSelected] = useState<any>(null);
    const [openDownloadPopup, setDownloadPopup] = useState<boolean>(false);
    const [openDeletePopup, setDeletePopup] = useState<boolean>(false);
    const [downloadId, setDownloadId] = useState<string>();
    const [deleteId, setDeleteId] = useState<string>();
    const [sortBy, setSortBy] = useState();
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const handleFullScreen = (item: any) => {
        setSelected(item);
    };

    const handleDownload = useCallback(async () => {
        setDownloadPopup(false);
        if (downloadId) {
            const response = await downloadResource({
                resourceId: downloadId
            });
            if (!response.errMsg && response.url) {
                try {
                    const newWindow = window.open(response.url, '_blank', 'noreferrer');
                    if (!newWindow) {
                        throw new Error('Failed to open a new window.');
                    }
                } catch (err) {
                    showToast('다운로드에 실패했습니다. 다시 시도해 주세요.');
                }
            } else {
                showToast('다운로드에 실패했습니다. 다시 시도해 주세요.');
            }
            setDownloadId('');
        }
    }, [downloadId]);

    const handleCloseDownloadPopup = () => {
        setDownloadId('');
        setDownloadPopup(false)
    };

    const handleCloseDeletePopup = () => {
        setDeleteId('');
        setDeletePopup(false)
    };

    const handleDelete = useCallback(async () => {
        setDeletePopup(false);
        if (deleteId) {
            const returnValue = await deleteResource({id: deleteId});
            if (returnValue) {
                dispatch(searchResource({
                    rowsPerPage,
                    pageNum: 1,
                    sortBy,
                    sortOrder: sortOrder === 'asc' ? '0' : '1'
                }));
            } else {
                showToast('파일 삭제를 실패했습니다. 다시 시도해 주세요.');
            }
            setDeleteId('');
        }
    }, [dispatch, showToast, deleteId, rowsPerPage, sortBy, sortOrder]);

    const handlePerRowsChange = (currentPerRows: number) => {
        dispatch(setRowsPerPage(currentPerRows));
    };

    const handlePageChange = useCallback((currentPage: number) => {
        dispatch(searchResource({
            rowsPerPage,
            pageNum: currentPage,
            sortBy,
            sortOrder: sortOrder === 'asc' ? '0' : '1'
        }));
    }, [dispatch, rowsPerPage, sortBy, sortOrder]);

    const handleSort = useCallback((selectedColumn: any, sortDirection: 'asc' | 'desc') => {
        console.log('handleSort ', selectedColumn.id, sortDirection);
        setSortBy(selectedColumn.id);
        setSortOrder(sortDirection);
    }, []);

    const columns: TableColumn<dataType>[] = [
        {
            id: 'type',
            name: '종류',
            //width: '6.25rem',
            grow: 0.0739,
            cell: row => {
                return (
                    <div className={css.typeBox}>
                        <CategoryOverlay
                            color={(row.type === 'image') ? 'green' : row.type === 'video' ? 'yellow' : undefined}
                        >
                            {(row.type === 'image') ? 'AI 이미지' : (row.type === 'video') ? 'AI 동영상' : ''}
                        </CategoryOverlay>
                    </div>
                );
            },
            sortable: true,
            center: true
        },
        {
            id: 'thumbnail',
            name: '',
            //width: '7.625rem',
            grow: 0.0902,
            cell: row => {
                return (
                    <div className={classNames(css.imgCell, {[css.placeholder]: !row.thumbnail})} onClick={() => handleFullScreen(row)}>
                        {(row.type === 'image') ?
                            <Image src={row.srcUrl} /> :
                            <Image src={row.thumbnail} />
                        }
                    </div>
                );
            }
        },
        {
            id: 'createdDate',
            name: '생성 일자',
            //width: '9.375rem',
            grow: 0.1110,
            cell: row => row.createdDate ? getTimeString(row.createdDate) : '',
            sortable: true
        },
        {
            id: 'styleName',
            name: '스타일명',
            //width: '7.5rem',
            grow: 0.0888,
            selector: row => (row.type === 'image')  ? row.styleName : '-',
            sortable: true
        },
        {
            id: 'prompt',
            name: '프롬프트',
            selector: row => (row.type === 'image') ? row.prompt : '-',
            allowOverflow: false,
            grow: 0.2663,
            //width: '21.5rem',
            //maxWidth: '21.5rem',
            sortable: true
        },
        {
            id: 'title',
            name: '타이틀',
            //width: '10.5rem',
            //maxWidth: '10.5rem',
            grow: 0.0962,
            wrap: true,
            selector: row => row.title,
            sortable: true
        },
        {
            id: 'broadDate',
            name: '방송 일자',
            //width: '9.375rem',
            grow: 0.1110,
            selector: row => row.broadDate ? getTimeString(row.broadDate) : '',
            sortable: true
        },
        {
            id: 'broadEpisode',
            name: '방송 회차',
            //width: '6.25rem',
            grow: 0.0739,
            selector: row => (!row.broadEpisode || row.broadEpisode === '-') ? '-' : `${row.broadEpisode}회`,
            sortable: true
        },
        {
            name: '',
            width: '5.625rem',
            //maxWidth: '5.625rem',
            grow: 0.0888,
            cell: row => {
                return (
                    <div className={css.toolCell}>
                        <Icon className={css.toolIcon} disabled={!row.enableDownload} onClick={() => {
                            setDownloadId(row.id);
                            setDownloadPopup(true)
                        }}>{'download'}</Icon>
                        <Icon className={css.toolIcon} onClick={() => {
                            setDeleteId(row.id);
                            setDeletePopup(true)
                        }}>{'trash'}</Icon>
                    </div>
                );
            }
        }
    ];

    const style = {
        tableWrapper: {
            style: {
                height: '100%'
            }
        },
        table: {
            style: {
                width: '100%',
                backgroundColor: '#1E1E22'
            }
        },
        head: {
            style: {
                zIndex: 2,
                top: '-1px'
            }
        },
        headRow: {
            style: {
                height: '2.5rem',
                backgroundColor: '#1E1E22',
                color: '#D4D0FF',
                borderBottom: '1px solid #444459',
                fontSize: '0.75rem'
            }
        },
        rows: {
            style: {
                backgroundColor: '#1E1E22',
                color: '#fff',
                height: '4.625rem',
                borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
                '&:not(:last-of-type)': {
                    borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
                }
            },
            highlightOnHoverStyle: {
                backgroundColor: '#5D6073',
                color: '#fff',
                outline: 'none',
                borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
            }
        },
        headCells: {
            style: {
                padding: '0 0.62rem',
                '& > div': {
                    '&:hover': {
                        opacity: 1
                    },
                    gap: '0.5rem',
                    '& > span': {
                        width: '1.25rem',
                        height: '1.25rem',
                        '& > svg': {
                            width: '1.25rem !important',
                            height: '1.25rem !important'
                        }
                    }
                }
            }
        },
        cells: {
            style: {
                padding: '0 0.62rem'
            }
        },
        pagination: {
            style: {
                backgroundColor: '#1E1E22',
                color: '#fff'
            },
            pageButtonsStyle: {
                color: '#fff',
                fill: '#fff',
                '&:disabled': {
                    cursor: 'unset',
                    color: 'rgba(255, 255, 255, 0.6)',
                    fill: 'rgba(255, 255, 255, 0.6)'
                },
                '&:hover:not(:disabled)': {
                    color: '#9FA0DA',
                    fill: '#9FA0DA',
                    backgroundColor: 'transparent',
                },
                '&:focus': {
                    backgroundColor: 'transparent'
                }
            },

        }

    };

    useEffect(() => {
        dispatch(searchResource({
            rowsPerPage,
            pageNum: 1,
            sortBy,
            sortOrder: sortOrder === 'asc' ? '0' : '1'
        }));
    }, [dispatch, contentType, rowsPerPage, createdDateFrom, createdDateTo, broadDate, keyword, sortBy, sortOrder]);

    return (
        <>
            <div className={css.listView}>
                {data && data.items.length > 0 ?
                    <ReactTable
                        className={css.reactTable}
                        columns={columns}
                        customStyles={style}
                        data={data.items}
                        fixedHeader
                        pagination
                        paginationServer
                        paginationTotalRows={data.totalPage}
                        sortServer
                        highlightOnHover
                        sortIcon={<SortIcon />}
                        noDataComponent={null}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationComponent={Pagination}
                        onSort={handleSort}
                    /> :
                    <NoData />
                }
            </div>
            <FullScreenPopup
                type={selectedItem?.type}
                open={!!selectedItem}
                onClose={() => setSelected(null)}
                onDownload={handleDownload}
                data={selectedItem}
            />
            <DownloadPopup
                open={openDownloadPopup}
                onClose={handleCloseDownloadPopup}
                onConfirm={handleDownload}
            />
            <DeletePopup
                open={openDeletePopup}
                onClose={handleCloseDeletePopup}
                onConfirm={handleDelete}
            />
        </>
    );
};

export default ListView;
