import classNames from 'classnames';
import { useCallback, useState } from 'react';
import AudioWave from '../../components/common/AudioWave/AudioWave';
import Button from '../../components/common/Button/Button';
import Toast from '../../components/common/Toast/Toast';
import { downloadResource } from '../../features/common/commonSlice';
import { useToast } from '../../utils/providers/toastProvider';
import VideoPlayer from '../AIVideo/VideoPlayer';
import css from './Completed.module.scss'


const Script = ({
    headline,
    text
}: {
    headline: string;
    text: string;
}) => {
    return (
        <div className={css.script}>
            <div className={css.headline}>{headline}</div>
            <div className={css.body}>{text}</div>
        </div>
    );
}

const Completed = ({
    type,
    dataInfo
}: {
    type: string;
    dataInfo?: any;
}) => {
    const { showToast } = useToast();

    const handleDownload = useCallback(async () => {
        if (dataInfo?.resourceId) {
            const response = await downloadResource({
                resourceId: dataInfo.resourceId
            });
            if (!response.errMsg && response.url) {
                window.open(response.url, '_blank', 'noreferrer');
            }
        }
    }, [dataInfo]);

    const handleCopy = async () => {
        try {
            const copyText = (document.querySelector('[data-id="ai-news-scripts"]') as HTMLElement).innerText;
            await navigator.clipboard.writeText(copyText);
            showToast('복사 되었습니다.');
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div className={css.viewer}>
                <div className={css.top}>
                    <Button icon="download" marked onClick={handleDownload}>{type === 'video' ? '동영상 다운로드' : '음성 파일 다운로드'}</Button>
                </div>
                <div className={css.contents}>
                    {type === 'video' ?
                        <div className={css.video}>
                            <VideoPlayer
                                url={dataInfo?.videoUrl}
                                thumbnail={dataInfo?.thumbnailUrl}
                            />
                        </div> :
                        <div className={css.waveform}>
                            <AudioWave audioSrc={dataInfo?.audioUrl} />
                        </div>
                    }
                </div>
                <div className={classNames(css.scriptContainer, {[css.audioType]: type === 'audio'})}>
                    <div data-id="ai-news-scripts" className={css.scripts}>
                        {
                            dataInfo?.script?.map((item: any) => {
                                return (
                                    <Script
                                        key={item.order}
                                        headline={item.headline}
                                        text={item.summary}
                                    />
                                );
                            })
                        }
                        <div className={css.copy}>
                            <Button icon="copy" onClick={handleCopy} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Completed;
