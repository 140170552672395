import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isDev } from '../../utils';
import { GET, POST } from '../../utils/api';
import { reporterType } from '../common/commonSlice';
import mock from '../../__mocks__/sample.json';

interface resizeType {
    x: number;
    y: number;
    width: number;
    height: number;
    wPercent: number;
    hPercent: number;
    xPercent: number;
    yPercent: number;
    flip: boolean;
}

interface sizeType {
    w: number;
    h: number;
}

export interface articleType {
    text: string;
    textLength: number;
}

export interface ttsInfoType {
    src: string;
}

export interface dataInfoType {
    resourceId: string;
    script: {
        order: number;
        summary: string;
        headline: string;
    }[];
    thumbnailUrl: string;
    videoUrl: string;
}

export interface humanDataType {
    bTitle: string;
    bDate: string | undefined;
    bEpisode: string;
    selectedReporter: reporterType;
    resize: resizeType;
    originalSize: sizeType;
    article: articleType;
    runningTime: number;
    ttsInfo: ttsInfoType;
    dataInfo: dataInfoType;
    ttsId: string;
    resourceId: string;
    loading: boolean;
}

export const initialState: humanDataType = {
    bTitle: '',
    bDate: new Date().toISOString(),
    bEpisode: '',
    selectedReporter: {
        broadcast: '',
        gender: '',
        id: '',
        name: '',
        speed: 1.0,
        src: '',
        profileUrl: '',
        fullProfileUrl: ''
    },
    resize: {
        x: 600,
        y: 30,
        width: 340,
        height: 500,
        wPercent: 100,
        hPercent: 100,
        xPercent: 62.5,
        yPercent: 5.55555,
        flip: false
    },
    originalSize: {
        w: 21.25, // rem value based 14 inch resolution
        h: 31.25, // rem value based 14 inch resolution
    },
    article: {
        text: isDev ? mock.articles[0] : '',
        textLength: 0,
    },
    runningTime: 0,
    ttsInfo: {
        src: ''
    },
    dataInfo: {
        resourceId: '',
        script: [],
        thumbnailUrl: '',
        videoUrl: ''
    },
    ttsId: '',
    resourceId: '',
    loading: false
};

export const createArticle = createAsyncThunk('/ai-human/create-article-tts', async (_, {getState}) => {
    const store = (getState() as any);
    const state = store.human;
    const params = {
        articleText: state.article.text,
        reporterId: state.selectedReporter.id,
        speed: state.selectedReporter.speed
    };
    const res = await POST('ai-human/create-article-tts', params);
    if (res.result === -1) {
        return '';
    } else {
        console.log('Created ttsId: ', res.ttsId);
        return res.ttsId;
    }
});

export const createVideo = createAsyncThunk('/ai-human/create-ai-human', async (_, {getState}) => {
    const store = (getState() as any);
    const state = store.human;
    const params = {
        broadDate: state.bDate,
        broadEpisode: state.bEpisode,
        position: {
            height: state.resize.hPercent,
            width: state.resize.wPercent,
            xPos: state.resize.xPercent,
            yPos: state.resize.yPercent,
            flip: state.resize.flip
        },
        reporterId: state.selectedReporter.id,
        title: state.bTitle,
        ttsId: state.ttsId
    };
    const res = await POST('ai-human/create-ai-human', params);
    if (res.result === -1) {
        if (res.errMsg) console.warn('[ai-human/create-ai-human] ', res.errMsg);
        return '';
    } else {
        return res.resourceId;
    }
});

export const getVideoStatus = async ({resourceId}: {resourceId: string;}) => {
    const params = new URLSearchParams({
        resourceId
    });
    return await GET('ai-human/get-ai-human-status', params);
};

export const getArticleStatus = async ({ttsId}: {ttsId: string;}) => {
    const params = new URLSearchParams({
        ttsId
    });
    return await GET('ai-human/get-article-tts-status', params);
};

export const humanSlice = createSlice({
    name: 'human',  // video creation
    initialState,
    reducers: {
        setTitle: (state: humanDataType, action: PayloadAction<string>) => {
            state.bTitle = action.payload;
        },
        setDate: (state: humanDataType, action: PayloadAction<string>) => {
            state.bDate = action.payload;
        },
        setEpisode: (state: humanDataType, action: PayloadAction<string>) => {
            state.bEpisode = action.payload;
        },
        setReporter: (state: humanDataType, action: PayloadAction<reporterType>) => {
            state.selectedReporter = action.payload;
        },
        setResize: (state: humanDataType, action: PayloadAction<resizeType>) => {
            state.resize = action.payload;
        },
        setArticle: (state: humanDataType, action: PayloadAction<articleType>) => {
            state.article = action.payload;
        },
        setRunningTime: (state: humanDataType, action: PayloadAction<number>) => {
            state.runningTime = action.payload;
        },
        setArticleStatus: (state: humanDataType, action: PayloadAction<ttsInfoType>) => {
            state.ttsInfo = action.payload;
            //state.ttsId = '';
        },
        setVideoStatus: (state: humanDataType, action: PayloadAction<dataInfoType>) => {
            state.dataInfo = action.payload;
            state.resourceId = '';
        },
        resetVideoStatus: (state: humanDataType) => {
            state.dataInfo = {
                resourceId: '',
                script: [],
                thumbnailUrl: '',
                videoUrl: ''
            };
        },
        resetArticleStatus: (state: humanDataType) => {
            state.ttsId = '';
            state.ttsInfo = {
                src: ''
            };
        },
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createArticle.fulfilled, (state, action) => {
            state.loading = false;
            state.ttsId = action.payload;
        });
        builder.addCase(createArticle.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createArticle.rejected, (state) => {
            state.loading = false;
            state.ttsId = '';
            state.ttsInfo = {
                src: ''
            };
        });

        builder.addCase(createVideo.fulfilled, (state, action) => {
            state.loading = false;
            state.resourceId = action.payload;
        });
        builder.addCase(createVideo.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createVideo.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const {
    setTitle,
    setDate,
    setEpisode,
    setReporter,
    setResize,
    setArticle,
    setRunningTime,
    setArticleStatus,
    setVideoStatus,
    resetVideoStatus,
    resetArticleStatus,
    reset
} = humanSlice.actions;

export default humanSlice.reducer;
