import axios from 'axios'
import { fetchAuthSession } from 'aws-amplify/auth';

axios.defaults.withCredentials = true;

let controllers: any[] = [];

export const API_URL = 'https://api-dev.ai-news-skb.net/'; // 'http://localhost:3030/'; // TODO:

export const Api = axios.create({
    baseURL: API_URL,
    validateStatus: (status) => status < 400,
    params: {}
});
Api.interceptors.request.use(async (config) => {
    try {
        const accessToken = await getAccessToken();
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    } catch (error) {
        console.error('Error in request interceptor:', error);
        return Promise.reject(error);
    }
});

// AccessToken을 가져오고 만료를 체크하는 함수
export const getAccessToken = async () => {
    try {
        let session = await fetchAuthSession();

        let accessToken = session.tokens?.accessToken;
        const expiresAt = session.tokens?.accessToken.payload.exp;

        if (expiresAt) {
            const currentTime = Math.floor(Date.now() / 1000); // 현재 시간
            // 60초 이내에 만료되는 경우 새로 고침
            if (expiresAt - currentTime < 60) {
                session = await fetchAuthSession({forceRefresh: true}); // 토큰 갱신
                accessToken = session.tokens?.accessToken;
            }
        }
        return accessToken || {};
    } catch (error) {
        console.error('Error getting access token:', error);
        return '';
    }
};

//export const getToken = async (): Promise<string> => {
//    const refreshToken = localStorage.getItem('refreshToken');
//    let token = localStorage.getItem('accessToken');

//    if (!token) {
//        if (refreshToken) {
//            // 다시 /auth/refresh-token 호출
//            // 토큰 갱신 서버통신
//            const { data } = await axios.post(API_URL + '/auth/refresh-token', {refreshToken: refreshToken});
//            if (data.error) {
//                if (data.error.code === 'ERR_104' || data.error.code === 'ERR_105') {
//                    localStorage.removeItem('accessToken');
//                    localStorage.removeItem('refreshToken');
//                    if (window.location.pathname !== '/') {
//                        window.location.href = "/";
//                    }
//                }
//            } else {
//                localStorage.setItem('lastExe', Date.now().toString());

//                /* data.item.refreshToken 값은 null */
//                token = data.item.accessToken;
//                localStorage.setItem('accessToken', data.item.accessToken);
//            }
//        }
//        return token || '';
//    }

//    return token || '';
//};

export const POST = async (func: any, params = {}, isJson = true) => {
    const controller = new AbortController();
    controllers.push(controller);

    try {
        const res = await Api.post(`${API_URL}${func}`, params, {
            signal: controller.signal,
            headers: {
                'Content-Type': isJson ? 'application/json' : 'multipart/form-data'
            }
        });
        if (res !== null && typeof res === 'object') {
            const {status, data} = res;
            switch (status) {
            case 200:
                return data;
            case 504:
                console.log('Gateway timeout!!!');
                return {
                    errMsg: '[504] Gateway timeout error.'
                };
            }
        } else {
            console.warn('API POST ERROR')
        }
    } catch (error: any) {
        if (axios.isCancel(error)) {
            console.log('[Canceled API]:', func);
        } else {
            console.error('API POST ERROR:', error);
            switch (error.response.status) {
            case 401:
                window.location.href = '/login';
                break;
            }
        }
    } finally {
        // 완료된 controller는 배열에서 제거
        controllers = controllers.filter(ctrl => ctrl !== controller);
    }
};

export const GET = async (url: any, params?: any, options?: any) => {
    const controller = new AbortController();
    controllers.push(controller);

    try {
        const res = await Api.get(
            `${API_URL}${url}`,
            {
                params,
                signal: controller.signal
            }
        );
        if (res !== null && typeof res === 'object') {
            const {status, data} = res;
            switch (status) {
            case 200:
                return data;
            default:
                console.warn('API GET ERROR');
                return null;
            }
        } else {
            console.warn('API GET ERROR');
        }
    } catch (error: any) {
        if (axios.isCancel(error)) {
            console.log('[Canceled API]:', url);
        } else {
            console.error('API GET ERROR:', error);
            switch (error.response?.status) {
            case 401:
                window.location.href = '/login';
                break;
            }
        }
    } finally {
        // 완료된 controller는 배열에서 제거
        controllers = controllers.filter(ctrl => ctrl !== controller);
    }
};

export const abortAllRequests = () => {
    console.log('current controllers: ', controllers);
    controllers.forEach(controller => controller.abort());
    controllers = [];
};

export const DOWNLOAD = async (filUrl: string) => {
    try {
        const response = await Api.get(filUrl, {
            responseType: 'blob', // important
        });

        // Create a URL for the blob object
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Extract filename from content-disposition header if available
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'downloaded_file';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
        }

        link.setAttribute('download', fileName); // specify filename if necessary
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file', error);
    }
}
