import Button from '../../components/common/Button/Button';
import ImageListItem from '../../components/common/ImageListItem/ImageListItem';
import VideoPlayer from './VideoPlayer';
import css from './VideoViewer.module.scss';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { downloadResource, saveResource } from '../../features/common/commonSlice';
import { useToast } from '../../utils/providers/toastProvider';

const VideoViewer = ({
    type,
    onRecreate
}: {
    type: string;
    onRecreate: () => void;
}) => {
    const { showToast } = useToast();
    const { dataInfo, attachedImg } = useSelector((state: RootState) => state.video);

    const handleSave = useCallback(async () => {
        if (dataInfo?.videoId) {
            const returnValue = await saveResource({
                resourceId: dataInfo.videoId
            });
            if (returnValue) {
                showToast('라이브러리에 저장하였습니다.');
            } else {
                showToast('라이브러리에 저장되지 않았습니다. 다시 시도해 주세요.');
            }
        }
    }, [dataInfo]);

    const handleDownload = useCallback(async () => {
        if (dataInfo?.videoId) {
            const response = await downloadResource({
                resourceId: dataInfo.videoId
            });
            if (!response.errMsg && response.url) {
                window.open(response.url, '_blank', 'noreferrer');
            }
        }
    }, [dataInfo]);

    return (
        <>
            <div className={css.viewer}>
                <div className={css.top}>
                    <Button priority="primary" icon="reset" onClick={onRecreate}>{`같은 ${type === 'image' ? '이미지' : '사진으'}로 다시 생성하기`}</Button>
                    <Button priority="primary" icon="library" onClick={handleSave}>{'라이브러리 저장하기'}</Button>
                    <Button icon="download" marked onClick={handleDownload}>{'동영상 다운로드'}</Button>
                </div>
                <div className={css.contents}>
                    <div className={css.video}>
                        <VideoPlayer
                            url={dataInfo.videoUrl}
                            backgroundImage={dataInfo.thumbnailUrl}
                        />
                    </div>
                </div>
                <div className={css.bottom}>
                    <div className={css.list}>
                        {attachedImg &&
                            <ImageListItem
                                src={URL.createObjectURL(attachedImg)}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default VideoViewer;
