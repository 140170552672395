import DatePicker from '../../components/common/DatePicker/DatePicker';
import Divider from '../../components/common/Divider/Divider';
import ProgressButton from '../../components/common/ProgressButton/ProgressButton';
import Text from '../../components/common/Text/Text';
import TextField from '../../components/common/TextField/TextField';
import ImageViewer from './ImageViewer';
import StyleList from '../common/StyleList';
import css from './AIImagePanel.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { setMenu } from '../../features/menu/menuSlice';
import { createImage, getStatus, resetImageData, resetStatus, setDate, setEpisode, setImageDescription, setStatus, setStyle, setTitle } from '../../features/image/imageSlice';
import { getStyleList } from '../../features/common/commonSlice';
import Standby from '../common/Standby';
import { abortAllRequests } from '../../utils/api';
import Spinner from '../../components/common/Spinner/Spinner';
import { useToast } from '../../utils/providers/toastProvider';


const AIImagePanel = () => {
    const {showToast} = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [enable, setEnable] = useState<boolean>(false);
    const { bTitle, bDate, bEpisode, selectedStyle, imageDescription, dataInfo, imageId, loading } = useSelector((state: RootState) => state.image);
    const { styleList } = useSelector((state: RootState) => state.common);
    const [progress, setProgress] = useState<number>(-1);
    const [creating, setCreating] = useState<boolean>(false);
    //const [playing, setPlaying] = useState(false);
    useEffect(() => {
        dispatch(setMenu('ai-image'));
        dispatch(resetImageData());
        dispatch(getStyleList());

        return () => {
            cancelCreating();
            dispatch(resetImageData());
        };
    }, [dispatch]);

    useEffect(() => {
        if (styleList.length > 0) {
            dispatch(setStyle(styleList[0].id));
        }
    }, [dispatch, styleList]);

    useEffect(() => {
        if (bTitle && typeof selectedStyle !== 'undefined' && imageDescription) {
            setEnable(true);
        } else {
            setEnable(false);
        }
    }, [bTitle, selectedStyle, imageDescription]);

    const cancelCreating = () => {
        abortAllRequests();
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setCreating(false);
        setProgress(-1);
    };

    const checkCreateStatus = useCallback(async (imageId: string) => {
        try {
            const response = await getStatus({imageId});
            if (!response) {
                showToast('이미지 생성 중 오류가 발생했습니다. 다시 시도해 주세요.');
                cancelCreating();
                return;
            }

            const {dataInfo, errMsg, progress, status} = response;

            setProgress(progress);
            if (status === 'succeeded') {
                setCreating(false);
                setProgress(-1);
                setEnable(false);
                dispatch(setStatus(dataInfo));
            } else if (status === 'failed') {
                cancelCreating();
            } else if (status !== 'succeeded') {
                timeoutRef.current = setTimeout(() => checkCreateStatus(imageId), 500);
            } else {
                console.error('Error checking upload status:', errMsg);
                cancelCreating();
            }
        } catch (error) {
            console.error('Error checking create status: ', error);
            cancelCreating();
        }
    }, [dispatch]);

    useEffect(() => {
        if (imageId) {
            setCreating(true);
            checkCreateStatus(imageId);
        }
    }, [imageId, checkCreateStatus]);

    const handleCreate = () => {
        dispatch(resetStatus());
        dispatch(createImage());
    };

    const handleChangeDate = (ev: any) => {
        dispatch(setDate(ev.toISOString()));
    };

    return (
        <div className={css.viewerPanel}>
            <div className={css.info}>
                <Text required>{'타이틀'}</Text>
                <TextField
                    disabled={creating}
                    value={bTitle}
                    onChange={(ev: any) => dispatch(setTitle(ev.target?.value))}
                />
                <div className={css.columns}>
                    <span>
                        <Text>{'방송 일자'}</Text>
                        <DatePicker
                            disabled={creating}
                            date={bDate ? new Date(bDate) : undefined}
                            onChange={handleChangeDate}
                        />
                    </span>
                    <span>
                        <Text>{'방송 회차'}</Text>
                        <TextField
                            type="number"
                            disabled={creating}
                            value={bEpisode}
                            onChange={(ev: any) => dispatch(setEpisode(ev.target?.value))}
                        />
                    </span>
                </div>
                <Divider />
                <Text desc="원하는 이미지 스타일을 선택해 주세요.">{'일러스트 스타일'}</Text>
                <div className={css.stylebox}>
                    <StyleList
                        data={styleList}
                        selected={selectedStyle}
                        disabled={creating}
                        onChange={(selected: string) => dispatch(setStyle(selected))}
                    />
                </div>
                <Text desc="원하는 이미지를 설명해 주세요.">{'텍스트 입력'}</Text>
                {/*<div onClick={() => setPlaying(!playing)}>애니메이션</div>*/}
                <TextField
                    className={css.explanation}
                    multiLine
                    value={imageDescription}
                    disabled={creating}
                    onChange={(ev: any) => dispatch(setImageDescription(ev.target.value))}
                />
                <div className={css.buttons}>
                    {creating ?
                        <div className={css.creatingNotice}>{'화면을 벗어나면 작업 내용이 모두 사라집니다.'}</div> : null
                    }
                    <ProgressButton
                        className={css.createBtn}
                        progress={progress}
                        disabled={!enable}
                        onClick={handleCreate}
                    >
                        {creating ? '이미지 생성중...' : '이미지 생성'}
                    </ProgressButton>
                </div>
            </div>
            <div className={css.container}>
                {creating ?
                    <Standby playing={creating} /> :
                    (dataInfo?.imageId && dataInfo?.imageUrl) ?
                        <ImageViewer onRecreate={handleCreate} /> :
                        <Standby />
                }
            </div>
            {loading && <Spinner blockOnClick scrim />}
        </div>
    );
}

export default AIImagePanel;
