import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GET, POST } from '../../utils/api';

export interface dataType {
    broadDate: string;
    broadEpisode: string;
    createdDate: string;
    enableDownload: boolean;
    id: string;
    info: string;
    progress: number;
    prompt: string;             // prompt by users
    internalPrompt: string;     // prompt by GPT
    reportCnt: number;
    reporterName: string;
    status: string;
    styleName: string;
    thumbnail: string;
    srcUrl: string;
    title: string;
    type: string;
}

export interface perPageType {
    id: string;
    label: string;
    value: number;
}

export interface dashboardDataType {
    data: {
        currentPage: number;
        errMsg: string;
        items: dataType[];
        result: number;
        totalPage: number;
    }
    createdDateFrom: string;
    createdDateTo: string;
    broadDate: string;
    keyword: string[];
    rowsPerPage: number;
    contentType: 'all' | 'image' | 'video';
    viewType: 'grid' | 'list';
    loading: boolean;
}



const defaultData = {
    currentPage: 1,
    errMsg: '',
    items: [],
    result: -1,
    totalPage: 1
};

const initialState: dashboardDataType = {
    data: {...defaultData},
    createdDateFrom: '',
    createdDateTo: '',
    broadDate: '',
    keyword: [],
    rowsPerPage: 10,
    contentType: 'all',
    viewType: 'grid',
    loading: false
};

interface pageInfoType {
    rowsPerPage: number | null;
    pageNum: number | null;
    sortBy?: string;
    sortOrder?: string;
}
/*
    * 정렬기준
    타입: 0
    생성일자: 1
    스타일명: 2
    사용자프롬프트: 3
    내부프롬프트: 4
    타이틀: 5
    방송일자: 6
    방송회차: 7

    * 정렬 순서
    오름차순: 0
    내림차순: 1
 */

const getSortByParams = (id?: string) => {
    if (!id) return '1';
    switch (id) {
    case 'type':
        return '0';
    case 'createdDate':
        return '1';
    case 'styleName':
        return '2';
    case 'prompt':
        return '3'
    case 'title':
        return '5';
    case 'broadDate':
        return '6';
    case 'broadEpisode':
        return '7';
    }
};

export const searchResource = createAsyncThunk('/dashboard/search-resource', async (pageInfo: pageInfoType, { getState }) => {
    const store = (getState() as any);
    const state = store.dashboard;
    const params = {
        type: state.contentType,
        createdDateFrom: state.createdDateFrom,
        createdDateTo: state.createdDateTo,
        broadDate: state.broadDate,
        sortBy: getSortByParams(pageInfo?.sortBy) || '1',
        sortOrder: pageInfo?.sortOrder || '0'
    };
    const query = new URLSearchParams(params);

    if (Array.isArray(state.keyword)) {
        query.append('keyword', state.keyword.join(','));
    }

    // optional
    if (pageInfo.rowsPerPage) {
        query.append('rowsPerPage', pageInfo.rowsPerPage.toString());
    }
    if (pageInfo.pageNum) {
        query.append('pageNum', pageInfo.pageNum.toString());
    }

    const res = await GET('dashboard/search-resource', query);

    if (!res.errMsg && res.result === 0) {
        return res;
    } else {
        return {...defaultData};
    }
});

export const deleteResource = async ({id}: {id: string}) => {
    const params = {
        resourceId: [id]
    };

    const res = await POST('dashboard/delete-resource', params);

    if (!res.errMsg) {
        return true;
    } else {
        return false;
    }
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setCreatedDateFrom: (state: dashboardDataType, action: PayloadAction<string>) => {
            state.createdDateFrom = action.payload;
        },
        setCreatedDateTo: (state: dashboardDataType, action: PayloadAction<string>) => {
            state.createdDateTo = action.payload;
        },
        setBroadDate: (state: dashboardDataType, action: PayloadAction<string>) => {
            state.broadDate = action.payload;
        },
        setKeyword: (state: dashboardDataType, action: PayloadAction<string[]>) => {
            state.keyword = action.payload;
        },
        setRowsPerPage: (state: dashboardDataType, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
        },
        setViewType: (state: dashboardDataType, action: PayloadAction<'grid' | 'list'>) => {
            state.viewType = action.payload;
        },
        setContentType: (state: dashboardDataType, action: PayloadAction<'all' | 'image' | 'video'>) => {
            state.contentType = action.payload;
        },
        setLoading: (state: dashboardDataType, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        resetFilter: (state: dashboardDataType) => {
            state.createdDateFrom = '';
            state.createdDateTo = '';
            state.broadDate = '';
            state.contentType = 'all';
            state.keyword = [];
        },
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(searchResource.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(searchResource.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchResource.rejected, (state) => {
            state.loading = false;
            state.data = {...defaultData};
        });
    }
});

export const {
    setCreatedDateFrom,
    setCreatedDateTo,
    setBroadDate,
    setKeyword,
    setRowsPerPage,
    setViewType,
    setContentType,
    setLoading,
    resetFilter,
    reset
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
