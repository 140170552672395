import { configureStore, combineReducers } from '@reduxjs/toolkit';
import dashboardSlice from '../features/dashboard/dashboardSlice';
import newsSlice from '../features/news/newsSlice';
import imageSlice from '../features/image/imageSlice';
import menuSlice from '../features/menu/menuSlice';
import humanSlice from '../features/human/humanSlice';
import commonSlice from '../features/common/commonSlice';
import videoSlice from '../features/video/videoSlice';

const rootReducer = combineReducers({
    menu: menuSlice,
    dashboard: dashboardSlice,
    image: imageSlice,
    video: videoSlice,
    human: humanSlice,
    news: newsSlice,
    common: commonSlice
});
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
