import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatDate } from '../../utils';
import { GET, POST } from '../../utils/api';

export interface dataInfoType {
    imageId: string;
    imageUrl: string;
    title: string;
}

interface dataType {
    bTitle: string;
    bDate: string;
    bEpisode: string;
    selectedStyle: string;
    imageDescription: string;
    dataInfo: dataInfoType;
    imageId: string;
    loading: boolean;
}

export interface styleListType {
    id: string;
    label: string;
    src: string;
}

export const createImage = createAsyncThunk('/ai-image/create-ai-image', async (_, {getState}) => {
    const state = (getState() as any).image;
    const params = {
        broadDate: state.bDate,
        broadEpisode: state.bEpisode,
        description: state.imageDescription,
        styleId: state.selectedStyle,
        title: state.bTitle
    };
    const res = await POST('ai-image/create-ai-image', params);
    if (res.result === -1) {
        if (res.errMsg) console.warn('[ai-image/create-ai-image] ', res.errMsg);
        return '';
    } else {
        return res.imageId;
    }
});

export const getStatus = async ({imageId}: {imageId: string}) => {
    const params = new URLSearchParams({
        imageId
    });
    return await GET('ai-image/get-ai-image-status', params);
};

const defaultDataInfo = {
    imageId: '',
    imageUrl: '',
    title: ''
};

export const initialState: dataType = {
    bTitle: '',
    bDate: '',
    bEpisode: '',
    selectedStyle: '',
    imageDescription: '',
    dataInfo: {...defaultDataInfo},
    imageId: '',
    loading: false
};

export const imageSlice = createSlice({
    name: 'image',  // image creation
    initialState,
    reducers: {
        setTitle: (state: dataType, action: PayloadAction<string>) => {
            state.bTitle = action.payload;
        },
        setDate: (state: dataType, action: PayloadAction<string>) => {
            state.bDate = action.payload;
        },
        setEpisode: (state: dataType, action: PayloadAction<string>) => {
            state.bEpisode = action.payload;
        },
        setStyle: (state: dataType, action: PayloadAction<string>) => {
            state.selectedStyle = action.payload;
        },
        setImageDescription: (state: dataType, action: PayloadAction<string>) => {
            state.imageDescription = action.payload;
        },
        setStatus: (state: dataType, action: PayloadAction<dataInfoType>) => {
            state.dataInfo = action.payload;
            state.imageId = '';
        },
        resetStatus: (state: dataType) => {
            state.dataInfo = {...defaultDataInfo};
        },
        resetImageData: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createImage.fulfilled, (state, action) => {
            state.loading = false;
            state.imageId = action.payload;
        });
        builder.addCase(createImage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createImage.rejected, (state) => {
            state.loading = false;
        });
    }
});

export const {
    setTitle,
    setDate,
    setEpisode,
    setStyle,
    setImageDescription,
    setStatus,
    resetStatus,
    resetImageData
} = imageSlice.actions;

export default imageSlice.reducer;
