import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from '../../components/common/DatePicker/DatePicker';
import Divider from '../../components/common/Divider/Divider';
import Uploader from '../../components/common/Uploader/Uploader';
import ProgressButton from '../../components/common/ProgressButton/ProgressButton';
import Tabs from '../../components/common/Tabs/Tabs';
import Text from '../../components/common/Text/Text';
import TextField from '../../components/common/TextField/TextField';
import { setMenu } from '../../features/menu/menuSlice';
import { createVideo, getStatus, resetStatus, resetVideoData, setAiImage, setDate, setEpisode, setStatus, setTitle } from '../../features/video/videoSlice';
import { AppDispatch, RootState } from '../../store';
import Standby from '../common/Standby';
import css from './AIVideoPanel.module.scss';
import VideoViewer from './VideoViewer';
import { abortAllRequests } from '../../utils/api';
import Spinner from '../../components/common/Spinner/Spinner';
import InProgress from '../common/InProgress';
import { useToast } from '../../utils/providers/toastProvider';

const AIVideoPanel = () => {
    const {showToast} = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const isUnmounted = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { bDate, bEpisode, bTitle, dataInfo, videoId, loading, aiImage } = useSelector((state: RootState) => state.video);
    const [activeTab, setTab] = useState<string>('image');
    const [progress, setProgress] = useState<number>(-1);
    const [creating, setCreating] = useState<boolean>(false);
    const [enable, setEnable] = useState<boolean>(false);
    const [file, setFile] = useState<File>();

    useEffect(() => {
        isUnmounted.current = false;
        dispatch(setMenu('ai-video'));
        dispatch(resetVideoData());

        return () => {
            console.log('[UNMOUNT!!!!!!!]')
            isUnmounted.current = true;
            cancelCreating();
            dispatch(resetVideoData());
            dispatch(setAiImage(null));
        };
    }, []);

    useEffect(() => {
        if (aiImage && aiImage.imageId) {
            // I2V 바로 생성하기
            dispatch(setTitle(aiImage.title));
            dispatch(resetStatus());
            dispatch(createVideo(aiImage.imageId));
        } else {
            const isFormValid = bTitle && (file || aiImage);
            setEnable(!!isFormValid);
        }
    }, [bTitle, file, aiImage]);

    const cancelCreating = () => {
        abortAllRequests();
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        setCreating(false);
        setProgress(-1);
    };

    const checkCreateStatus = useCallback(async (videoId: string) => {
        if (isUnmounted.current) return;

        try {
            const response = await getStatus({videoId});
            if (!response) {
                showToast('동영상 생성 중 오류가 발생했습니다. 다시 시도해 주세요.');
                cancelCreating();
                return;
            }

            const {dataInfo, errMsg, progress, status} = response;

            setProgress(progress);
            if (status === 'succeeded') {
                setCreating(false);
                setProgress(-1);
                setEnable(false);
                dispatch(setStatus(dataInfo));
            } else if (status === 'failed') {
                cancelCreating();
            } else if (status !== 'succeeded') {
                timeoutRef.current = setTimeout(() => checkCreateStatus(videoId), 500); // 2초 후에 다시 확인
            } else {
                console.error('Error checking upload status:', errMsg);
                cancelCreating();
            }
        } catch (error) {
            console.error('Error checking create status: ', error);
            cancelCreating();
        }
    }, [dispatch]);

    useEffect(() => {
        if (videoId) {
            setCreating(true);
            checkCreateStatus(videoId);
        }
    }, [videoId, checkCreateStatus]);

    const handleCreate = useCallback(() => {
        dispatch(resetStatus());
        if (file) {
            dispatch(createVideo(file));
        } else {
            console.warn('Failed to create a video. There is no file!');
        }
    }, [file]);

    const handleChangeDate = (ev: any) => {
        dispatch(setDate(ev.toISOString()));
    };

    const handleSetImg = useCallback((ev: any) => {
        if (ev.file) {
            //dispatch(setAttachedImg(ev.file));
            setFile(ev.file);
        }
    }, [dispatch]);

    const handleDelete = useCallback(() => {
        dispatch(setAiImage(null));
        setFile(undefined);
    }, [dispatch]);

    //const handleSetPic = useCallback((ev: {
    //    resourceId: string;
    //    thumbnailUrl: string;
    //    src: string;
    //    filename: string;
    //}) => {
    //    dispatch(setAttachedPic({...ev}));
    //}, [dispatch]);

    return (
        <div className={css.viewerPanel}>
            <div className={css.info}>
                <Text required>{'타이틀'}</Text>
                <TextField
                    disabled={creating}
                    value={bTitle || aiImage?.title}
                    onChange={(ev: any) => dispatch(setTitle(ev.target?.value))}
                />
                <div className={css.columns}>
                    <span>
                        <Text>{'방송 일자'}</Text>
                        <DatePicker
                            disabled={creating}
                            date={bDate ? new Date(bDate) : undefined}
                            onChange={handleChangeDate}
                        />
                    </span>
                    <span>
                        <Text>{'방송 회차'}</Text>
                        <TextField
                            type="number"
                            disabled={creating}
                            value={bEpisode}
                            onChange={(ev: any) => dispatch(setEpisode(ev.target?.value))}
                        />
                    </span>
                </div>
                <Divider />
                <Tabs
                    options={[
                        {value: 'image', label: '이미지'},
                        {value: 'picture', label: '사진', disabled: true}
                    ]}
                    onSelect={(selected: string) => setTab(selected)}
                />
                {
                    activeTab === 'image' ?
                        <>
                            <Text desc="동영상으로 만들기 원하는 이미지를 업로드 해주세요.">{'이미지 업로드'}</Text>
                            {aiImage ?
                                <Uploader
                                    data={{
                                        filename: aiImage.imageId,
                                        src: aiImage.imageUrl,
                                        thumbnailUrl: ''
                                    }}
                                    className={css.uploader}
                                    type="image"
                                    disabled={creating}
                                    onComplete={handleSetImg}
                                    onDelete={handleDelete}
                                /> :
                                <Uploader
                                    noUpload
                                    className={css.uploader}
                                    type="image"
                                    disabled={creating}
                                    onComplete={handleSetImg}
                                    onDelete={handleDelete}
                                />
                            }
                        </> :
                        <>
                            {/*<Text desc="원하는 이미지 스타일을 선택해 주세요.">{'일러스트 스타일'}</Text>
                            <div className={css.stylebox}>
                                <StyleList
                                    data={styleList}
                                    selected={styleId}
                                    onChange={(selected: string) => dispatch(setStyleId(selected))}
                                />
                            </div>
                            <Text desc="동영상으로 만들기 원하는 사진을 업로드 해주세요.">{'사진 업로드'}</Text>
                            <Uploader
                                className={css.uploader}
                                type="image"
                                data={attachedPic}
                                onComplete={handleSetPic}
                            />*/}
                        </>
                }

                <div className={css.buttons}>
                    {creating ?
                        <div className={css.creatingNotice}>{'화면을 벗어나면 작업 내용이 모두 사라집니다.'}</div> : null
                    }
                    <ProgressButton
                        className={css.createBtn}
                        progress={progress}
                        disabled={!enable}
                        onClick={handleCreate}
                    >
                        {creating ? '동영상 생성중...' : '동영상 생성'}
                    </ProgressButton>
                </div>
            </div>
            <div className={css.container}>
                {creating ?
                    <InProgress
                        videoUrl="https://d2hvlli5jyop8j.cloudfront.net/2_Sub_ID_A.mp4"
                        content={'AI 동영상을 제작 중입니다.'}
                    /> :
                    (dataInfo?.videoId && dataInfo?.videoUrl) ?
                        <VideoViewer onRecreate={handleCreate} type={activeTab} /> :
                        <Standby />
                }
            </div>
            {loading && <Spinner blockOnClick scrim />}
        </div>
    );
}

export default AIVideoPanel;
