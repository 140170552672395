import React, { createContext, useState, useContext } from 'react';
import Toast from '../../components/common/Toast/Toast';

interface ToastContextType {
    showToast: (content: string) => void;   // 호출 시그니처
}

const ToastContext = createContext<ToastContextType | null>(null);

export const ToastProvider = ({ children }: {children: JSX.Element}) => {
    const [toast, setToast] = useState({ content: '', open: false });

    const showToast = (content: string) => {
        // 메시지 업데이트 및 상태 활성화
        setToast({ content, open: true });

        // 1.5초 후 자동으로 사라지게 설정
        setTimeout(() => {
            setToast({ content: '', open: false });
        }, 1500);
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            <Toast
                content={toast.content}
                open={toast.open}
            />
        </ToastContext.Provider>
    );
};

export const useToast = (): ToastContextType => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};
