import classNames from 'classnames';
import { useState, useRef, useCallback, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Icon from '../../components/common/Icon/Icon';
import ProgressBar from '../../components/common/ProgressBar/ProgressBar';
import Hotkeys from 'react-hot-keys';
import css from './VideoPlayer.module.scss';

const VideoPlayer = ({
    className,
    url,
    width = '100%',
    height = '100%',
    thumbnail = '',
    backgroundImage = '',
    backgroundColor,
    noHandle,
    showControlAlways,
    loop = false,
    noTimeInfo,
    controlsClassName,
    rate = 1,
    leftSlot,
    rightSlot
}: {
    className?: string;
    url: string;
    width?: string;
    height?: string;
    thumbnail?: string;
    backgroundImage?: string;
    backgroundColor?: string;
    noHandle?: boolean;
    showControlAlways?: boolean;
    loop?: boolean;
    noTimeInfo?: boolean;
    controlsClassName?: string;
    rate?: number;
    leftSlot?: JSX.Element;
    rightSlot?: JSX.Element;
}) => {
    const playerRef = useRef(null);
    const [src, setSrc] = useState(url);
    const [played, setPlayed] = useState(0); // 현재 재생 시간
    const [duration, setDuration] = useState(0);
    const [ready, setReady] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [isControlsVisible, setIsControlsVisible] = useState(true);
    const [isEffectVisible, setIsEffectVisible] = useState(false);
    const [thumb, setThumb] = useState<string>('');

    useEffect(() => {
        if (url) {
            setSrc(url);
        }
    }, [url]);

    useEffect(() => {
        setThumb(thumbnail);
    }, [thumbnail]);

    const handleKeyUp = (ev: any) => {
        if (ready && ev === 'space' && played > 0) {
            togglePlay();
            setIsEffectVisible(true);
            setTimeout(() => {
                setIsEffectVisible(false);
            }, 500);
        }
    };

    const togglePlay = useCallback(() => {
        setPlaying(!playing);
    }, [playing]);

    const handleChangeProgress = (value: number) => {
        setPlayed(value);
        (playerRef?.current as any)?.seekTo(value);
    };

    const handlePlay = () => {
        setTimeout(() => {
            setIsControlsVisible(false);
        }, 3000);
    };

    const handleEnd = () => {
        (playerRef?.current as any)?.seekTo(0);
        setPlaying(false);
    };

    const handleReady = () => {
        setReady(true);
    };

    return (
        <Hotkeys
            keyName="space"
            onKeyUp={handleKeyUp}
        >
            <div className={classNames(css.videoPlayer, className, {[css.pause]: !playing})} onKeyUp={handleKeyUp}>
                <div
                    className={css.playerWrapper}
                    onClick={togglePlay}
                    style={{
                        backgroundImage: backgroundImage ? `url("${backgroundImage}")` : undefined,
                        backgroundColor: backgroundColor || undefined
                    }}
                >
                    <ReactPlayer
                        ref={playerRef} // 실제 영상 재생 위치 조절
                        className={css.player}
                        url={src}
                        width={width}
                        height={height}
                        playIcon={<Icon size="large">{playing ? 'pause' : 'play'}</Icon>}
                        light={thumb ? thumb : true}
                        playing={playing}
                        controls={false}
                        loop={loop}
                        onPlay={handlePlay}
                        onEnded={handleEnd}
                        onReady={handleReady} // 영상이 로드되어 준비된 상태
                        onDuration={setDuration} // 총 재생 시간
                        onError={(ev: any) => console.error('Video error: ', ev)}
                        onProgress={({ played }) => {setPlayed(played)}} // 현재 재생 시간
                        playbackRate={rate}
                    />
                    {ready ?
                        <>
                            <div className={css.playingStatus}>
                                <Icon size="large">{playing ? 'pause' : 'play'}</Icon>
                            </div>
                            <Icon size="large" className={classNames(css.effectIcon, {[css.showEffect]: isEffectVisible})}>{playing ? 'play' : 'pause'}</Icon>
                        </> : null
                    }
                </div>
                {
                    ready ?
                        <div className={classNames(css.controls, controlsClassName, {
                            [css.hidden]: !showControlAlways && !isControlsVisible
                        })}>
                            <ProgressBar
                                min={0.00000}
                                max={0.99999}
                                step={0.00001}
                                percentValue={played}
                                currentTime={played * duration}
                                duration={duration}
                                onChange={handleChangeProgress}
                                noHandle={noHandle}
                                noTimeInfo={noTimeInfo}
                                leftSlot={leftSlot}
                                rightSlot={rightSlot}
                            />
                        </div> : null
                }
            </div>
        </Hotkeys>
    );
};

export default VideoPlayer;
