import { useCallback, useEffect, useState } from 'react';
import Button from '../../components/common/Button/Button';
import Divider from '../../components/common/Divider/Divider';
import Dropdown from '../../components/common/Dropdown/Dropdown';
import Tag from '../../components/common/Tag/Tag';
import TextField from '../../components/common/TextField/TextField';
import FilterPopup from './FilterPopup';
import css from './Dashboard.module.scss';
import ListView from './ListView';
import GridView from './GridView';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { reset, resetFilter, setBroadDate, setContentType, setCreatedDateFrom, setCreatedDateTo, setKeyword, setViewType } from '../../features/dashboard/dashboardSlice';
import { getTimeString } from '../../utils';

interface contentDataType {
    id: 'all' | 'image' | 'video';
    label: string;
}
const TYPE_OPTIONS: contentDataType[] = [
    {id: 'all', label: '전체'},
    {id: 'image', label: 'AI 이미지'},
    {id: 'video', label: 'AI 동영상'}
];

const Dashboard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {createdDateFrom, createdDateTo, broadDate, contentType, viewType, keyword} = useSelector((state: RootState) => state.dashboard);
    const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
    const [inputText, setInputText] = useState<string>('');
    const [tags, setTag] = useState<{type: 'keyword' | 'broadDate' | 'createdDate'; label: string; createdDateFrom?: string; createdDateTo?: string; broadDate?: string;}[]>([]);

    useEffect(() => {
        dispatch(reset());
    }, []);

    useEffect(() => {
        const _tags = tags.filter(item => (item.type !== 'createdDate' && item.type !== 'broadDate'));
        if (createdDateFrom && createdDateTo) {
            _tags.push({
                type: 'createdDate',
                label: `생성일자: ${getTimeString(createdDateFrom, 'yyyy.MM.dd')}~${getTimeString(createdDateTo, 'yyyy.MM.dd')}`,
                createdDateFrom,
                createdDateTo
            });
        }
        if (broadDate) {
            _tags.push({
                type: 'broadDate',
                label: `방송일자: ${getTimeString(broadDate, 'yyyy.MM.dd')}`,
                broadDate
            });
        }
        setTag(_tags);
    }, [createdDateFrom, createdDateTo, broadDate])

    const handleSelectType = (ev: contentDataType) => {
        dispatch(setContentType(ev.id));
    };

    const removeTag = ({info, index}: {info: any, index: number}) => {
        console.log(info, index);

        const updates = tags.filter((_, idx) => index !== idx);
        setTag(updates);

        switch (info.type) {
        case 'createdDate':
            // 생성일자 제거
            dispatch(setCreatedDateFrom(''));
            dispatch(setCreatedDateTo(''));
            break;
        case 'broadDate':
            // 방송일자 제거
            dispatch(setBroadDate(''));
            break;
        case 'keyword':
            //키워드 제거
            searchKeyword('remove', info.label);
            break;
        }
    };

    const handleChangeSearch = (ev: any) => {
        setInputText(ev.target?.value.trim() || '');
    };

    const handleViewType = useCallback(() => {
        if (viewType === 'grid') {
            dispatch(setViewType('list'));
        } else {
            dispatch(setViewType('grid'));
        }
    }, [viewType, dispatch]);

    const resetDashboard = () => {
        setTag([]);
        dispatch(resetFilter());
    };

    const handleKeyUp = (ev: any) => {
        if (ev.code === 'Enter') {
            searchKeyword('add');
        }
    };

    const searchKeyword = useCallback((change: 'add' | 'remove', removeWord?: string) => {
        if (change === 'add') {
            if (inputText && !keyword.includes(inputText)) {
                dispatch(setKeyword([...keyword, inputText]));
                setTag([{type: 'keyword', label: inputText}, ...tags]);
                setInputText(''); // 입력창 초기화
            }
        } else {
            dispatch(setKeyword(keyword.filter(item => item !== removeWord)));
        }
    }, [dispatch, inputText, keyword, tags]);

    return (
        <>
            <div className={css.dashboard}>
                <div className={css.header}>
                    <div className={css.top}>
                        <Dropdown
                            className={css.typemenu}
                            options={TYPE_OPTIONS}
                            selected={contentType}
                            onSelect={handleSelectType}
                        />
                        <div className={css.right}>
                            <Button icon={viewType} onClick={handleViewType} />
                            <TextField
                                className={css.search}
                                icon="search"
                                placeholder="ex. 뉴스 타이틀, 회차, 키워드"
                                value={inputText}
                                onKeyUp={handleKeyUp}
                                onChange={handleChangeSearch}
                            />
                            <Button icon="filter" onClick={() => setOpenFilter(true)} />
                        </div>
                    </div>
                    <div className={css.bottom}>
                        <div className={css.tags}>
                            {tags.map((f, idx) => {
                                return (
                                    <Tag key={idx} onRemove={() => removeTag({info: f, index: idx})}>{f.label}</Tag>
                                );
                            })}
                        </div>
                        <Button icon="reset" priority="secondary" type="line" inline onClick={resetDashboard}>{'초기화'}</Button>
                    </div>
                    <Divider narrow />
                </div>
                {viewType === 'grid' ?
                    <GridView /> :
                    <ListView />
                }
            </div>
            <FilterPopup
                open={isOpenFilter}
                onClose={() => setOpenFilter(false)}
                //onApply={handleApplyFilter}
            />
        </>
    );
}

export default Dashboard;
