import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatDate } from '../../utils';
import { GET, POST } from '../../utils/api';

interface dataInfoType {
    videoId: string;
    thumbnailUrl: string;
    //usedImageUrl: string;
    videoUrl: string;
}

interface attachedResourceType {
    resourceId: string;
    thumbnailUrl: string;
    src: string;
    filename: string;
}

interface dataType {
    bTitle: string;
    bDate: string;
    bEpisode: string;
    attachedImg: File | null;
    //attachedImg: attachedResourceType;
    //attachedPic: attachedResourceType;
    //styleId: string;
    dataInfo: dataInfoType;
    videoId: string;
    loading: boolean;
    aiImage: {
        imageId: string;
        imageUrl: string;
        title: string;
    } | null;
}

export interface styleListType {
    id: string;
    label: string;
    src: string;
}

export const createVideo = createAsyncThunk('/ai-video/create-ai-video', async (file: File | string, {getState}) => {
    const state = (getState() as any).video;
    const formData = new FormData();
    formData.append('broadDate', state.bDate);
    formData.append('broadEpisode', state.bEpisode.toString());
    formData.append('title', state.bTitle);

    if (typeof file === 'string') {
        formData.append('imageId', file);
    } else {
        formData.append('file', file);
    }

    const res = await POST('ai-video/create-ai-video', formData, false);
    if (res.result === -1) {
        if (res.errMsg) console.warn('[ai-image/create-ai-image] ', res.errMsg);
        return '';
    } else {
        return res.videoId;
    }
});

export const getStatus = async ({videoId}: {videoId: string}) => {
    const params = new URLSearchParams({
        videoId
    });
    return await GET('ai-video/get-ai-video-status', params);
};

const defaultResource = {
    resourceId: '',
    thumbnailUrl: '',
    src: '',
    filename: ''
};

export const initialState: dataType = {
    bTitle: '',
    bDate: '',
    bEpisode: '',
    attachedImg: null,
    //attachedPic: {...defaultResource},
    //styleId: '',
    dataInfo: {
        videoId: '',
        thumbnailUrl: '',
        //usedImageUrl: '',
        videoUrl: ''
    },
    //styleList: [],
    videoId: '',
    aiImage: null,
    loading: false
};

export const videoSlice = createSlice({
    name: 'video',  // video creation
    initialState,
    reducers: {
        setTitle: (state: dataType, action: PayloadAction<string>) => {
            state.bTitle = action.payload;
        },
        setDate: (state: dataType, action: PayloadAction<string>) => {
            state.bDate = action.payload;
        },
        setEpisode: (state: dataType, action: PayloadAction<string>) => {
            state.bEpisode = action.payload;
        },
        //setStyleId: (state: dataType, action: PayloadAction<string>) => {
        //    state.styleId = action.payload;
        //},
        setAttachedImg: (state: dataType, action: PayloadAction<File | null>) => {
            state.attachedImg = action.payload;
        },
        //setAttachedPic: (state: dataType, action: PayloadAction<{resourceId: string, thumbnailUrl: string; src: string; filename: string;}>) => {
        //    state.attachedPic = action.payload;
        //},
        setStatus: (state: dataType, action: PayloadAction<dataInfoType>) => {
            state.dataInfo = action.payload;
            state.videoId = '';
        },
        setAiImage: (state: dataType, action: PayloadAction<{imageId: string; imageUrl: string; title: string;} | null>) => {
            state.aiImage = action.payload;
        },
        resetStatus: (state: dataType) => {
            state.dataInfo = {
                videoId: '',
                thumbnailUrl: '',
                videoUrl: ''
            };
        },
        resetVideoData: (state: dataType) => {
            const {aiImage} = state;
            return {
                ...initialState,
                aiImage
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createVideo.fulfilled, (state, action) => {
            state.loading = false;
            state.videoId = action.payload;
        });
        builder.addCase(createVideo.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(createVideo.rejected, (state, action) => {
            state.loading = false;
        });
    }
});

export const {
    setTitle,
    setDate,
    setEpisode,
    //setStyleId,
    setAttachedImg,
    //setAttachedPic,
    setStatus,
    setAiImage,
    resetStatus,
    resetVideoData
} = videoSlice.actions;

export default videoSlice.reducer;
