import classNames from 'classnames';
import css from './Divider.module.scss';

const Divider = ({
    narrow
}: {
    narrow?: boolean;
}) => {
    return (
        <div
            className={classNames(css.divider, {[css.narrow]: narrow})}
        />
    );
};

export default Divider;
