import { useDispatch, useSelector } from 'react-redux';
import GridItem from '../../components/Dashboard/GridItem';
import { AppDispatch, RootState } from '../../store';
import css from './GridView.module.scss'
import { useCallback, useEffect, useState } from 'react';
import FullScreenPopup from './FullScreenPopup';
import { downloadResource } from '../../features/common/commonSlice';
import DownloadPopup from '../../components/Dashboard/DownloadPopup';
import NoData from './NoData';
import { useToast } from '../../utils/providers/toastProvider';
import Spinner from '../../components/common/Spinner/Spinner';
import { searchResource, setLoading } from '../../features/dashboard/dashboardSlice';

const GridView = () => {
    const { showToast } = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const [openDownloadPopup, setDownloadPopup] = useState<boolean>(false);
    const [selectedItem, setSelected] = useState<any>(null);
    const [downloadId, setDownloadId] = useState<string>();
    const { data, loading, contentType, createdDateFrom, createdDateTo, broadDate, keyword } = useSelector((state: RootState) => state.dashboard);

    const handleFullScreen = (item: any) => {
        setSelected(item);
    };

    const handleDownload = useCallback(async () => {
        setDownloadPopup(false);
        if (downloadId) {
            const response = await downloadResource({
                resourceId: downloadId
            });
            if (!response.errMsg && response.url) {
                try {
                    const newWindow = window.open(response.url, '_blank', 'noreferrer');
                    if (!newWindow) {
                        throw new Error('Failed to open a new window.');
                    }
                } catch (err) {
                    showToast('다운로드에 실패했습니다. 다시 시도해 주세요.');
                }
            } else {
                showToast('다운로드에 실패했습니다. 다시 시도해 주세요.');
            }
            setDownloadId('');
        }
    }, [downloadId]);

    const handleOpenPopup = (id: string) => {
        setDownloadId(id);
        setDownloadPopup(true);
    };

    const handleClosePopup = () => {
        setDownloadId('');
        setDownloadPopup(false)
    };

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(searchResource({
            rowsPerPage: null,
            pageNum: null,
            sortBy: '1',
            sortOrder: '1'
        }));
    }, [dispatch, contentType, createdDateFrom, createdDateTo, broadDate, keyword]);

    return (
        <>
            <div className={css.list}>
                {loading ?
                    <Spinner /> :
                    <>
                        {data && data.items.length > 0 ?
                            <div className={css.gridView}>
                                {data.items.map(item => {
                                    return (
                                        <GridItem
                                            key={item.id}
                                            data={item}
                                            onFullScreen={() => handleFullScreen(item)}
                                            onDownload={() => handleOpenPopup(item.id)}
                                        />
                                    );
                                })}
                            </div> :
                            data.result === 0 ?
                                <NoData /> : <Spinner />
                        }
                    </>
                }
            </div>
            <FullScreenPopup
                type={selectedItem?.type}
                open={!!selectedItem}
                onClose={() => setSelected(null)}
                onDownload={(id: string) => handleOpenPopup(id)}
                data={selectedItem}
            />
            <DownloadPopup
                open={openDownloadPopup}
                onClose={handleClosePopup}
                onConfirm={handleDownload}
            />
        </>
    );
};

export default GridView;
