import Popup from '../../components/common/Popup/Popup';
import DatePicker from '../../components/common/DatePicker/DatePicker';
import Text from '../../components/common/Text/Text';
import Icon from '../../components/common/Icon/Icon';
import css from './FilterPopup.module.scss'
import Button from '../../components/common/Button/Button';
import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { setBroadDate, setCreatedDateFrom, setCreatedDateTo } from '../../features/dashboard/dashboardSlice';
import { formatDate } from '../../utils';

const RangeButton = ({children, selected, onClick}: {
    children: string;
    selected?: boolean;
    onClick?: () => void;
}) => {
    return (
        <div className={classNames(css.rangeButton, {[css.selected]: selected})} onClick={onClick}>
            {children}
        </div>
    );
};

type rangeType = 'none' | '1month' | '3months' | '6months' | '5years';

const FilterPopup = ({
    open,
    onClose,
    //onApply
}: {
    open?: boolean;
    onClose: () => void;
    //onApply: () => void;
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const {createdDateFrom, createdDateTo, broadDate} = useSelector((state: RootState) => state.dashboard);
    const [range, setRange] = useState<rangeType>('none');
    const [startDate, setStartDate] = useState<Date | undefined>(createdDateFrom ? new Date(createdDateFrom) : undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(createdDateTo ? new Date(createdDateTo) : undefined);
    const [bDate, setBDate] = useState<Date | undefined>(broadDate ? new Date(broadDate) : undefined);

    const updateStartDate = useCallback((endDate: Date, range: rangeType) => {
        let startDate = null;
        switch (range) {
        case '1month':
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate());
            break;
        case '3months':
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 3, endDate.getDate());
            break;
        case '6months':
            startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 6, endDate.getDate());
            break;
        case '5years':
            startDate = new Date(endDate.getFullYear() - 5, endDate.getMonth(), endDate.getDate());
            break;
        default:
            startDate = startDate ? startDate : endDate;
        }
        return startDate;
    }, []);

    const updateEndDate = useCallback((startDate: Date, range: rangeType) => {
        let endDate = null;
        switch (range) {
        case '1month':
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
            break;
        case '3months':
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, startDate.getDate());
            break;
        case '6months':
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 6, startDate.getDate());
            break;
        case '5years':
            endDate = new Date(startDate.getFullYear() + 5, startDate.getMonth(), startDate.getDate());
            break;
        default:
            endDate = endDate ? endDate : startDate;
        }
        return endDate;
    }, []);

    const handleChangeDate = useCallback((date: Date, startEnd: 'start' | 'end') => {
        if (range === 'none') {
            if (startEnd === 'start') {
                setStartDate(date);
                if (!endDate) {
                    setEndDate(date);
                }
            } else {
                setEndDate(date);
                if (!startDate) {
                    setStartDate(date);
                }
            }
        } else {
            let startDate = null;
            let endDate = null;
            if (startEnd === 'start') {
                startDate = date;
                endDate = updateEndDate(date, range);
            } else {
                endDate = date;
                startDate = updateStartDate(date, range);
            }
            setStartDate(startDate);
            setEndDate(endDate);
        }
    }, [range, startDate, endDate]);


    const cancel = () => {
        onClose();
        refresh();
    };

    const apply = useCallback(() => {
        if (startDate) {
            dispatch(setCreatedDateFrom(formatDate(startDate)));
        }
        const _endDate = endDate? endDate : startDate;
        if (_endDate) {
            dispatch(setCreatedDateTo(formatDate(_endDate)));
        }
        if (bDate) {
            dispatch(setBroadDate(formatDate(bDate)));
        }
        onClose();
    }, [dispatch, onClose, startDate, endDate, bDate]);

    const handleSelectRange = useCallback((selected: rangeType) => {
        if (selected === range) {
            setRange('none');
        } else {
            setRange(selected);
            if (endDate) {
                const startDate = updateStartDate(endDate, selected);
                setStartDate(startDate);
            } else if (startDate) {
                const endDate = updateEndDate(startDate, selected);
                setEndDate(endDate);
            } else {
                // No start, end dates
            }
        }
    }, [updateStartDate, range, startDate, endDate]);

    const refresh = useCallback(() => {
        setStartDate(createdDateFrom ? new Date(createdDateFrom) : undefined);
        setEndDate(createdDateTo ? new Date(createdDateTo) : undefined);
        setBDate(broadDate ? new Date(broadDate) : undefined);
        setRange('none');
    }, [createdDateFrom, createdDateTo, broadDate]);

    useEffect(() => {
        refresh();
    }, [createdDateFrom, createdDateTo, broadDate]);

    return (
        <Popup
            open={open}
            position="center center"
            onClose={cancel}
        >
            <div className={css.filterPopup}>
                <div className={css.content}>
                    <div className={css.title}>{'검색 기간'}</div>
                    <div className={css.section}>
                        <Text>{'생성일자'}</Text>
                        <div className={css.range}>
                            <RangeButton selected={range === '1month'} onClick={() => handleSelectRange('1month')}>{'1개월'}</RangeButton>
                            <RangeButton selected={range === '3months'} onClick={() => handleSelectRange('3months')}>{'3개월'}</RangeButton>
                            <RangeButton selected={range === '6months'} onClick={() => handleSelectRange('6months')}>{'6개월'}</RangeButton>
                            <RangeButton selected={range === '5years'} onClick={() => handleSelectRange('5years')}>{'최대(5년)'}</RangeButton>
                        </div>
                        <div className={css.createdDate}>
                            <DatePicker
                                date={startDate}
                                wrapperClassName={css.datePicker}
                                onChange={(date: Date) => handleChangeDate(date, 'start')}
                            />
                            <Icon size="tiny">{'tilde'}</Icon>
                            <DatePicker
                                placement="bottom-start"
                                date={endDate}
                                wrapperClassName={css.datePicker}
                                onChange={(date: Date) => handleChangeDate(date, 'end')}
                            />
                        </div>
                    </div>
                    <div className={css.section}>
                        <Text>{'방송일자'}</Text>
                        <DatePicker
                            date={bDate}
                            wrapperClassName={css.datePicker}
                            onChange={(date: Date) => setBDate(date)}
                        />
                    </div>
                </div>
                <div className={css.buttons}>
                    <Button onClick={cancel} type="line">{'취소'}</Button>
                    <Button onClick={apply}>{'적용'}</Button>
                </div>
            </div>
        </Popup>
    );
};

export default FilterPopup;
