import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/common/Button/Button';
import Image from '../../components/common/Image/Image';
import ImageListItem from '../../components/common/ImageListItem/ImageListItem';
import { downloadResource, saveResource } from '../../features/common/commonSlice';
import { dataInfoType } from '../../features/image/imageSlice';
import { setAiImage } from '../../features/video/videoSlice';
import { AppDispatch, RootState } from '../../store';
import { useToast } from '../../utils/providers/toastProvider';
import css from './ImageViewer.module.scss';

const ImageViewer = ({
    onRecreate
}: {
    onRecreate?: () => void;
}) => {
    const { showToast } = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const listRef = useRef(null);
    const { dataInfo } = useSelector((state: RootState) => state.image);
    const [imageData, setImageData] = useState<dataInfoType>();

    useEffect(() => {
        if (dataInfo.imageId && dataInfo.imageUrl) {
            setImageData(dataInfo);
        }
    }, [dataInfo]);

    const handleSaveImage = useCallback(async () => {
        if (imageData?.imageId) {
            const returnValue = await saveResource({
                resourceId: imageData.imageId
            });
            if (returnValue) {
                showToast('라이브러리에 저장하였습니다.');
            } else {
                showToast('라이브러리에 저장되지 않았습니다. 다시 시도해 주세요.');
            }
        }
    }, [imageData]);

    const handleDownload = useCallback(async () => {
        if (imageData?.imageId) {
            const response = await downloadResource({
                resourceId: imageData.imageId
            });
            if (!response.errMsg && response.url) {
                window.open(response.url, '_blank', 'noreferrer');
            } else {
                showToast('이미지 다운로드에 실패했습니다.');
            }
        }
    }, [imageData]);

    const createVideo = useCallback(() => {
        if (imageData?.imageId) {
            console.log('dataInfo', dataInfo);
            dispatch(setAiImage(imageData));
            navigate('/ai-video');
        }
    }, [imageData, dataInfo]);

    return (
        <>
            <div className={css.viewer}>
                <div className={css.top}>
                    <Button priority="primary" icon="reset" onClick={onRecreate}>{'비슷한 이미지 다시 생성하기'}</Button>
                    <Button priority="primary" icon="video" onClick={createVideo}>{'동영상으로 만들기'}</Button>
                    <Button priority="primary" icon="library" onClick={handleSaveImage}>{'라이브러리 저장하기'}</Button>
                    <Button icon="download" marked onClick={handleDownload}>{'이미지 다운로드'}</Button>
                </div>
                <div className={css.contents}>
                    <div className={css.image}>
                        {imageData?.imageUrl &&
                        <Image src={imageData.imageUrl} />
                        }
                    </div>
                </div>
                <div className={css.bottom}>
                    <div ref={listRef} className={css.list}>
                        {imageData?.imageId &&
                            <ImageListItem
                                key={imageData.imageId}
                                src={imageData.imageUrl}
                                selected    // single item in list
                                //onClick={() => setImageData(item)}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImageViewer;
