import { useCallback } from 'react';
import Icon from '../../components/common/Icon/Icon';
import Image from '../../components/common/Image/Image';
import Popup from '../../components/common/Popup/Popup';
import VideoPlayer from '../AIVideo/VideoPlayer';
import css from './FullScreenPopup.module.scss'


const FullScreenPopup = ({
    type,
    open,
    data,
    onClose,
    onDownload
}: {
    type: 'image' | 'video',
    open?: boolean;
    data?: any;
    onClose?: () => void;
    onDownload?: (id: string) => void;
}) => {

    const handleDownload = useCallback(() => {
        onDownload && onDownload(data.id);
    }, [onDownload, data]);

    return (
        <Popup
            open={open}
            onClose={onClose}
            useCloseButton
        >
            <div className={css.popupContent}>
                <div className={css.viewer}>
                    {
                        (type === 'image') ?
                            <div className={css.imageWrapper}>
                                <Image src={data?.srcUrl} />
                                <Icon className={css.download} onClick={handleDownload}>{'download'}</Icon>
                            </div> :
                            <VideoPlayer
                                url={data?.srcUrl}
                                loop
                                backgroundImage={data?.thumbnail}
                                rightSlot={
                                    <Icon className={css.download} onClick={handleDownload}>{'download'}</Icon>
                                }
                            />
                    }
                </div>
                <div className={css.script}>
                    {data?.styleName && <div className={css.styleName}>{data?.styleName}</div>}
                    {data?.prompt && <div className={css.prompt}>{data?.prompt}</div>}
                    {data?.internalPrompt && <div className={css.internalPrompt}>{`AI 추천 텍스트: ${data?.internalPrompt}`}</div>}
                </div>
            </div>
        </Popup>
    );
};

export default FullScreenPopup;
