import { useEffect } from 'react';
import css from './Toast.module.scss'


const Toast = ({
    open,
    content,
    onClose
}: {
    open?: boolean;
    content: string;
    onClose?: () => void;
}) => {
    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                onClose && onClose();
            }, 1500); // 1.5초 후에 사라지게 함
            return () => clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 정리
        }
    }, [open, onClose]);

    return (
        <>
            {open ?
                <div className={css.toast}>
                    <span>
                        {content}
                    </span>
                </div> : null
            }
        </>
    );
};

export default Toast;
