import axios from 'axios';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { Api, API_URL } from '../../../utils/api';
import VideoPlayer from '../../../views/AIVideo/VideoPlayer';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import Popup from '../Popup/Popup';
import Spinner from '../Spinner/Spinner';
import Guide from './Guide';
import css from './Uploader.module.scss';

interface completeEventType {
    resourceId?: string;
    thumbnailUrl?: string;
    src?: string;
    filename?: string;
    checked?: boolean;
    file?: File;
}

const Uploader = ({
    type,
    className,
    data,
    disabled,
    useCheckPopup,
    noUpload,
    onComplete,
    onUploadStart,
    onUploadEnd,
    onDelete
}: {
    type: 'image' | 'video',
    className?: string;
    data?: {
        filename: string;
        src: string;
        thumbnailUrl: string;
    };
    disabled?: boolean;
    useCheckPopup?: boolean;
    noUpload?: boolean;
    onComplete?: (ev: completeEventType) => void;
    onUploadStart?: () => void;
    onUploadEnd?: () => void;
    onDelete?: () => void;
}) => {
    const componentCss = classNames(css.fileUploader, className, {[css.disabled]: disabled});
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [uploading, setUploading] = useState<boolean>(false);
    const [openUploadPopup, setUploadPopup] = useState<boolean>(false);
    const [openDeletePopup, setDeletePopup] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [attachedFile, setFile] = useState<{file: File, preview: string}>();
    const [loading, setLoading] = useState<boolean>(false);
    const [controller, setController] = useState<AbortController>();
    const [getController, setGetController] = useState<AbortController>();

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            console.log('[Unmount] Cancel to requests.');
            if (controller) {
                controller.abort();
            }

            if (getController) {
                getController.abort();
            }
        };
    }, []);

    const checkUploadStatus = async (resourceId: string) => {
        const controller = new AbortController();
        setGetController(controller);

        try {
            const response = await Api.get(
                `${API_URL}/common/get-upload-status?resourceId=${resourceId}`,
                {
                    signal: controller.signal
                }
            );
            const { status, dataInfo, errMsg } = response.data;
            // ready, succeeded, failed
            if (status === 'succeeded') {
                setUploading(false);
                const fileInfo = {
                    resourceId,
                    thumbnailUrl: dataInfo.thumbnailUrl,
                    src: dataInfo.src,
                    filename: dataInfo.filename,
                    checked: useCheckPopup
                };
                onComplete && onComplete(fileInfo);
            } else if (status !== 'succeeded') {
                timeoutRef.current = setTimeout(() => checkUploadStatus(resourceId), 1000); // 2초 후에 다시 확인
            } else {
                console.error('Error checking upload status:', errMsg);
                setUploading(false);
                onUploadEnd && onUploadEnd();
            }
        } catch (error) {
            console.error('Error checking upload status: ', error);
            setUploading(false);
            onUploadEnd && onUploadEnd();
            setError(true);
        }
    };

    const {getRootProps, getInputProps, open, isDragAccept, isDragReject} = useDropzone({
        accept: type === 'image' ? {
            'image/*': []
        } : {
            'video/mp4': []
        },
        noClick: true,
        multiple: false,
        onDrop: async (acceptedFiles) => {
            if (noUpload) {
                setFile({
                    file: acceptedFiles[0],
                    preview: URL.createObjectURL(acceptedFiles[0])
                });
                setLoading(true);
                return;
            }

            const newController = new AbortController();
            setController(newController);

            const formData = new FormData();
            formData.append('type', type);
            formData.append('file', acceptedFiles[0]);
            onUploadStart && onUploadStart();
            setUploading(true);
            await Api.post(`${API_URL}/common/upload-resource`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                signal: newController.signal
            }).then(res => {
                if ((res.status === 200 || res.status === 202) && res.data.resourceId) {
                    checkUploadStatus(res.data.resourceId);
                } else {
                    console.error('Error uploading file:', res.data.errMsg);
                    setUploading(false);
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.log('[Canceled API] upload-resource');
                }
                console.error('Error uploading file:', err);
                setUploading(false);
                setError(true);
            });
        }
    });

    const confirmUpload = () => {
        setUploadPopup(false);
    };

    const cancelUpload = () => {
        setUploadPopup(false);
    };

    const confirmDelete = () => {
        setDeletePopup(false);
        setFile(undefined);
        onDelete && onDelete();
    };

    const handleLoad = useCallback(() => {
        if (attachedFile) {
            URL.revokeObjectURL(attachedFile.preview);
            onComplete && onComplete({file: attachedFile.file});
        }
        setLoading(false);
    }, [onComplete, attachedFile]);

    const handleError = () => {
        setLoading(false);
        setFile(undefined);
    };

    const isValid = (obj?: {filename: string; src: string}) => obj && !!(obj?.filename && obj?.src);
    return (
        <div className={componentCss}>
            <div className={css.dropzoneWrapper} onClick={open}>
                <div {...getRootProps({className: classNames(
                    css.dropzone,
                    {
                        [css.dragAccept]: isDragAccept,
                        [css.dragReject]: isDragReject
                    }
                )})}>
                    <input {...getInputProps()} />
                    {uploading || loading?
                        <Spinner /> :
                        !isValid(data) ? <Guide type={type} hidden={(!!attachedFile || (data && isValid(data)))} /> : null
                    }
                </div>
                {!uploading && isValid(data) &&
                <div className={css.hoverarea}>
                    <Guide type={type} overlay hidden={(!!attachedFile || (data && isValid(data)))} />
                </div>
                }
            </div>
            {!uploading && (attachedFile || (data && isValid(data))) &&
                <>
                    {type === 'image' ?
                        <div className={css.player}>
                            {(noUpload && attachedFile) ?
                                <img
                                    alt=""
                                    src={attachedFile && attachedFile.preview}
                                    onLoad={handleLoad}
                                    onError={handleError}
                                    style={{
                                        display: loading ? 'none' : 'block'
                                    }}
                                /> :
                                <img alt="" src={data?.src} />
                            }
                        </div> :
                        <VideoPlayer
                            className={css.player}
                            url={data?.src || ''}
                            backgroundImage={data?.thumbnailUrl}
                        />
                    }
                    {!disabled && ((data && isValid(data)) || attachedFile) ?
                        <>
                            <div className={css.fileName} onClick={open}>{data?.filename || attachedFile?.file.name}</div>
                            <Icon size="tiny" className={css.delete} onClick={() => setDeletePopup(true)}>{'delete'}</Icon>
                        </> : null
                    }
                </>
            }
            <Popup
                open={openUploadPopup}
                content={'첨부 파일 변경 시, 기존 내용은 모두 초기화됩니다.\n교체 하시겠습니까?'}
                onClose={() => {
                    setUploadPopup(false)}
                }
                buttons={
                    <>
                        <Button type="line" onClick={cancelUpload}>{'취소'}</Button>
                        <Button onClick={confirmUpload}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={openDeletePopup}
                content={`첨부된 ${type === 'image' ? '이미지를' : '동영상을'} 삭제하시겠습니까?`}
                onClose={() => {
                    setDeletePopup(false)}
                }
                buttons={
                    <>
                        <Button type="line" onClick={() => setDeletePopup(false)}>{'취소'}</Button>
                        <Button onClick={confirmDelete}>{'확인'}</Button>
                    </>
                }
            />
            <Popup
                open={error}
                content={'첨부 파일 업로드를 실패했습니다. 다시 시도해 주세요.'}
                onClose={() => setError(false)}
                buttons={
                    <>
                        <Button onClick={() => setError(false)}>{'확인'}</Button>
                    </>
                }
            />
        </div>
    );
}
export type { completeEventType };
export default Uploader;
