import classNames from 'classnames';
import Logo from '../../components/common/Logo/Logo';
import css from './Standby.module.scss'


const Standby = ({
    playing
}: {
    playing?: boolean;
}) => {
    return (
        <div className={classNames(css.standby, {[css.playing]: playing})}>
            <div className={css.bg}>
                <Logo direction="horizontal" className={css.logo} />
                {playing ?
                    <>
                        <div className={classNames(css.circle, css.delay1)} />
                        <div className={classNames(css.circle, css.delay2)} />
                        <div className={classNames(css.circle, css.delay3)} />
                        <div className={classNames(css.circle, css.delay4)} />
                        <div className={classNames(css.circle, css.delay5)} />
                        <div className={classNames(css.circle, css.delay6)} />
                        <div className={classNames(css.circle, css.delay7)} />
                        <div className={classNames(css.circle, css.delay8)} />
                        <div className={classNames(css.circle, css.delay9)} />
                        <div className={classNames(css.circle, css.delay10)} />
                    </> : null
                }
            </div>
        </div>
    );
};

export default Standby;
