import { dataType } from '../../features/dashboard/dashboardSlice';
import { formatDate } from '../../utils';
import CategoryOverlay from '../common/CategoryOverlay/CategoryOverlay';
import Icon from '../common/Icon/Icon';
import Image from '../common/Image/Image';
import Tooltip from '../common/Tooltip/Tooltip';
import css from './GridItem.module.scss';

const GridItem = ({
    data,
    onFullScreen,
    onDownload
}: {
    data: dataType;
    onFullScreen?: () => void;
    onDownload?: () => void;
}) => {
    return (
        <div
            className={css.gridItem}
        >
            <div className={css.bg}>
                {(data.type === 'image') ?
                    (data.srcUrl) && <Image src={data.srcUrl} /> :
                    (data.thumbnail) && <Image src={data.thumbnail} />
                }
            </div>
            {data.type &&
                <CategoryOverlay
                    className={css.category}
                    color={data.type === 'image' ? 'green' : data.type === 'video' ? 'yellow' : undefined}
                >
                    {data.type === 'image' ? 'AI 이미지' : data.type === 'video' ? 'AI 동영상' : ''}
                </CategoryOverlay>
            }
            <Tooltip
                content={
                    <span>
                        {`생성 일자: ${formatDate(new Date(data.createdDate))}\n타이틀: ${data.title}\n방송 일자: ${(!data.broadDate || data.broadDate === '-') ? '-' : formatDate(new Date(data.broadDate))}\n방송 회차: ${data.broadEpisode}회`}
                    </span>
                }
            >
                <div className={css.info}>
                    <Icon className={css.icon} size="mini">{'infoDark'}</Icon>
                </div>
            </Tooltip>
            <div className={css.icons}>
                <Icon className={css.icon} onClick={onFullScreen}>{'fullscreen'}</Icon>
                {data.enableDownload && <Icon className={css.icon} onClick={onDownload}>{'download'}</Icon>}
            </div>
        </div>
    );
};

export default GridItem;
