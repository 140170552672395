import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../components/common/Text/Text';
import { setMenu } from '../../features/menu/menuSlice';
import { AppDispatch, RootState } from '../../store';
import css from './AINewsPanel.module.scss';
import TextField from '../../components/common/TextField/TextField';
import DatePicker from '../../components/common/DatePicker/DatePicker';
import Divider from '../../components/common/Divider/Divider';
import Tabs from '../../components/common/Tabs/Tabs';
import ProgressButton from '../../components/common/ProgressButton/ProgressButton';
import StepItem from '../../components/common/StepItem/StepItem';
import classNames from 'classnames';
import Icon from '../../components/common/Icon/Icon';
import ReporterSelection from '../common/ReporterSelection';
import Completed from '../common/Completed';
import { createNews, getStatus, reset, resetStatus, setDate, setEpisode, setReporter, setStatus, setTitle } from '../../features/news/newsSlice';
import AIArticle from './AIArticle';
import Template from './Template';
import { isEmptyObject } from '../../utils';
import InProgress from '../common/InProgress';
import { reporterType } from '../../features/common/commonSlice';
import { abortAllRequests } from '../../utils/api';
import Spinner from '../../components/common/Spinner/Spinner';
import { useToast } from '../../utils/providers/toastProvider';

const NewsPanel = () => {
    const {showToast} = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const reporterRef = useRef(null);
    const newsState = useSelector((state: RootState) => state.news);
    const { bTitle, bDate, bEpisode, selectedReporter, validAiArticleCnt, selectedTemplate, selectedBg, selectedBgm, resourceId, dataInfo, loading } = newsState;
    const [activeTab, setTab] = useState<string>('withHuman');    // withHuman, headlineOnly
    const [enable, setEnable] = useState<boolean>(false);
    const [step, setStep] = useState<'reporter' | 'article' | 'template' | 'videoProgress' | 'completed'>('reporter');
    const [progress, setProgress] = useState<number>(-1);
    const [creating, setCreating] = useState<boolean>(false);

    useEffect(() => {
        dispatch(reset());
        dispatch(setMenu('ai-news'));

        return () => {
            console.log('Unmount AI News.');
            cancelCreating();
            dispatch(reset());
        }
    }, []);

    useEffect(() => {
        const hasRequiredFields = bDate && !isEmptyObject(selectedReporter) && validAiArticleCnt >= 3 && selectedTemplate && selectedBg && selectedBgm;
        const isFormValid = hasRequiredFields;
        setEnable(!!isFormValid);
    }, [activeTab, bDate, selectedReporter, validAiArticleCnt, selectedTemplate, selectedBg, selectedBgm]);

    const handleCompleteReporter = (ev: reporterType) => {
        dispatch(setReporter(ev));
        setStep('article');
    };

    const handleCompleteArticle = () => {
        setStep('template');
    };

    const handleChangeDate = (ev: any) => {
        dispatch(setDate(ev.toISOString()));
    };

    const cancelCreating = () => {
        abortAllRequests();
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setCreating(false);
        setProgress(-1);
    };

    const checkCreateStatus = useCallback(async (resourceId: string) => {
        try {
            const response = await getStatus({resourceId});
            if (!response) {
                showToast('동영상 생성 중 오류가 발생했습니다. 다시 시도해 주세요.');
                cancelCreating();
                return;
            }
            const {dataInfo, errMsg, progress, status} = response;

            setProgress(progress);
            if (status === 'succeeded') {
                setCreating(false);
                setProgress(-1);
                setEnable(false);
                dispatch(setStatus(dataInfo));
                setStep('completed');
            } else if (status === 'failed') {
                cancelCreating();
            } else if (status !== 'succeeded') {
                timeoutRef.current = setTimeout(() => checkCreateStatus(resourceId), 500); // 2초 후에 다시 확인
            } else {
                console.error('Error checking upload status:', errMsg);
                cancelCreating();
            }
        } catch (error) {
            console.error('Error checking create status: ', error);
            setStep('template');
            cancelCreating();
        }
    }, [dispatch]);

    useEffect(() => {
        if (resourceId) {
            setCreating(true);
            checkCreateStatus(resourceId);
        }
    }, [resourceId, checkCreateStatus]);

    const handleCreate = () => {
        dispatch(resetStatus());
        dispatch(createNews());
    };

    const handleSelectTab = (selected: string) => {
        dispatch(reset());
        setTab(selected);
        setStep('reporter');
        if (reporterRef.current) {
            (reporterRef.current as any).reset();
        }
    };

    return (
        <div className={css.viewerPanel}>
            <div className={css.info}>
                <Text>{'타이틀'}</Text>
                <TextField
                    disabled={creating}
                    onChange={(ev: any) => dispatch(setTitle(ev.target.value))}
                    value={bTitle}
                />
                <div className={css.columns}>
                    <span>
                        <Text>{'방송 일자'}</Text>
                        <DatePicker
                            disabled={creating}
                            date={bDate ? new Date(bDate) : new Date()}
                            onChange={handleChangeDate}
                        />
                    </span>
                    <span>
                        <Text>{'방송 회차'}</Text>
                        <TextField
                            type="number"
                            disabled={creating}
                            onChange={(ev: any) => dispatch(setEpisode(ev.target.value))}
                            value={bEpisode}
                        />
                    </span>
                </div>
                <Divider />
                <Tabs
                    options={[
                        {value: 'withHuman', label: 'AI 기자 음성 포함'},
                        {value: 'headlineOnly', label: 'AI 기자 음성만'}
                    ]}
                    onSelect={handleSelectTab}
                />
                <div className={css.steps}>
                    <StepItem
                        title="AI 기자"
                        icon="human"
                        guidanceStr="원하는 AI 기자를 선택해 주세요."
                        selected={!creating && step === 'reporter'}
                        disabled={creating}
                        selectedValues={selectedReporter.name && selectedReporter.speed ? [
                            selectedReporter.name,
                            `${selectedReporter.speed} 배속`
                        ] : undefined}
                        imgSrc={selectedReporter.profileUrl}
                        onClick={() => setStep('reporter')}
                    />
                    <Icon className={classNames(css.stepArrowIcon, {[css.active]: (step !== 'completed')})} size="tiny">{'downTriangle'}</Icon>
                    <StepItem
                        title="기사 입력"
                        icon="articleInput"
                        guidanceStr="기사 내용을 입력해 주세요."
                        selected={!creating && step === 'article'}
                        disabled={creating || (step !== 'article' && (validAiArticleCnt < 3))}
                        selectedValues={validAiArticleCnt > 0 ? [
                            `기사 ${validAiArticleCnt}개 입력 완료`
                        ] : undefined}
                        onClick={() => setStep('article')}
                    />
                    <Icon className={classNames(css.stepArrowIcon, {[css.active]: (step === 'template' || step === 'article')})} size="tiny">{'downTriangle'}</Icon>
                    <StepItem
                        title="템플릿 설정"
                        icon="tamplete"
                        guidanceStr="배경화면과 음악을 선택해주세요."
                        selected={!creating && step === 'template'}
                        disabled={creating || (step !== 'template' && (!selectedBg || !selectedBgm))}
                        selectedValues={selectedBg && selectedBgm && selectedTemplate ? ['배경화면과 음악 선택 완료'] : undefined}
                        onClick={() => setStep('template')}
                    />
                </div>


                <div className={css.buttons}>
                    {creating ?
                        <div className={css.creatingNotice}>{'화면을 벗어나면 작업 내용이 모두 사라집니다.'}</div> : null
                    }
                    <ProgressButton
                        className={css.createBtn}
                        progress={progress}
                        disabled={!enable}
                        onClick={handleCreate}
                    >
                        {creating ? '동영상 생성중...' : step === 'completed' ? '동영상 생성 완료' : '동영상 생성'}
                    </ProgressButton>
                </div>
            </div>
            <div className={css.container}>
                {creating ?
                    <InProgress
                        content={'AI 동영상을 제작 중입니다.'}
                    /> :
                    (step === 'reporter' ?
                        <ReporterSelection
                            ref={reporterRef}
                            onComplete={handleCompleteReporter}
                            reporterSpeed={selectedReporter.speed}
                        /> :
                        step === 'article' ?
                            <AIArticle withHuman={activeTab === 'withHuman'} onComplete={handleCompleteArticle} /> :
                            step === 'template' ?
                                <Template withHuman={activeTab === 'withHuman'} /> :
                                <Completed type="video" dataInfo={dataInfo} />
                    )
                }
            </div>
            {loading && <Spinner blockOnClick scrim />}
        </div>
    );
}

export default NewsPanel;
